import axios from 'axios';
import { decode } from '../config/crypto.js';
import Swal from 'sweetalert2'

const header = (method, url, isAuth, data, toSendAFile, timeout) => 
{
  let options = { method }      

  options.url = url    
  data && (options.data = data)
  options.headers = {'Content-Type': toSendAFile ? 'multipart/form-data' : 'application/json' }
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
  options.timeout = timeout
  
  if (isAuth) {
    const data = localStorage.getItem('authInfo')? decode(localStorage.getItem('authInfo')):null
    const token = (data? data.token: null);
    token && (options.headers = {'Authorization': `Bearer ${token}`});
  }
  return options
}
  
const Header = (method, url, isAuth = false, data = null, toSendAFile = false, timeout= null ) =>

new Promise((resolve, reject) => {
    axios(header(method, url, isAuth, data, toSendAFile, timeout, timeout))
    .then(res => resolve(res))
    .catch(error => {
      // console.log(error.response, 'error')
      if (error.response && (error.response.data.message === 'Given token not valid for any token type') ){
        localStorage.clear();
        window.location.replace('/')
      }
      Swal.fire({
        title: '',
        icon: 'error',
        html: error.response.data.message,
        focusConfirm: false,
        confirmButtonText:'Aceptar',
      })
      reject(error)
    })
})
export default Header;

  
