import React ,{useEffect, useState, useContext} from 'react';
import { motion } from "framer-motion"
import '../assets/base/css/style.scss';
import '../assets/base/css/login.css';

import $ from 'jquery';

import {useForm} from '../components/formularios/useForm.js';

import AuthContext from '../contexts/auth/AuthContext';

export const Login = (props) => {

    const [see, setSee] = useState(false)

    const initialState = { 
        feedusername:false,
        feedpass:false
    }

    const [{username,password}, handleInputChange] = useForm({
        username:'',
        password:''
    });

    const query = new URLSearchParams(props.location.search)
    const next = query.get('next')

    const { loginFunction, isAuthenticated, message } = useContext(AuthContext)


    useEffect(() => {        
        if(isAuthenticated){
            if (next==='/#'){
                window.location.replace(process.env.REACT_APP_MENU_URL)
            }else{
                window.location.replace(next)
            }
        }

        if(message === "este usuario no existe"){
            setFeeds({feedpass:false,feedusername:true})
        }else if (message === "contraseña incorrecta") {
            setFeeds({feedpass:true,feedusername:false})
        }else if (message === "nombre de usuario no existe o es incorrecto") {
            setFeeds({feedpass:false,feedusername:true})
        }else{
            
        }
    }, [isAuthenticated, message]); // eslint-disable-line react-hooks/exhaustive-deps


    const [feeds, setFeeds ] = useState(initialState);

    const feedusername = feeds.feedusername
    const feedpass = feeds.feedpass

    const seePass = () =>
    {                

        setSee(!see)
        if(see)
        {            
            $('#id_password').attr('type', 'password')            
        } else {          
            $('#id_password').attr('type', 'text')            
        }
    }


    const handleSubmit =(e) =>{
        e.preventDefault();
        loginFunction({documento:username, password})
    }


    return (
        <>
            <div id="app">
                <section className="section">
                <div className="container mt-5">
                    <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 m-auto">
                        <motion.div 
                        initial={{ scale: 0 }} 
                        animate={{ rotate: 360, scale: 1 , delay: 1}} 
                        whileHover={{ scale: 1.2, rotate: 360 }}
                        // transition={{ delay: 0.5}} 
                        whileTap={{scale: 1,rotate: -1, borderRadius: "100%"}} 
                        className="login-brand">
                            <img src="../../assets/images/cubo_rojo.ico" alt="logo" width="100" className="shadow-light rounded-circle flotar"/>
                        </motion.div>

                        <div className="card card-primary">
                        <div className="card-header"><h4>Login</h4></div>

                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="needs-validation" >
                                <small className="text-danger">
                                </small>
                                <div className="form-group">
                                    
                                        <label className="control-label" htmlFor="id_username">Documento</label>
                                        <input tabIndex="1" autoComplete="off" value={username} onChange={ handleInputChange } type="text" name="username"  className={feedusername===false?'form-control grupo':'form-control grupo is-invalid'} placeholder="" id="id_username" maxLength="25" required/>
                                        <i className="icon fas fa-user" id="togglePassword"></i>
                                        <div className="invalid-feedback">
                                            Numero de documento no existe o no es valido
                                        </div>
                                  
                                </div>

                                <div className="form-group">
                                        <label htmlFor="" className="control-label">Contraseña</label> 
                                  
                                        <input  tabIndex="2" autoComplete="on" className={feedpass===false?'form-control grupo':'form-control grupo is-invalid'} onChange={ handleInputChange } type="password" value={password} name="password" id="id_password" required/>
                                        <i className={`fa -see-pass ${(!see) ? 'icon fa-eye' : 'icon fa-eye-slash'}`} onClick={seePass} id="togglePassword"></i>
                                        <div className="invalid-feedback">
                                            Por favor ingresa o verifica tu contraseña
                                        </div>
                                   
                                </div>

                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                    <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me"/>
                                    <label className="custom-control-label" htmlFor="remember-me">Recuérdame</label>
                                    </div>
                                </div>


                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
                                    Login
                                    </button>
                                </div>
                            </form>
                    
                        </div>
                        </div>
                        <div className="simple-footer">
                        Copyright &copy; Big Denim 2022
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>

            <script src="https://code.jquery.com/jquery-3.3.1.min.js" integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8=" crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossOrigin="anonymous"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.nicescroll/3.7.6/jquery.nicescroll.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js"></script>        
        </>
    );
};
