import React, {useEffect} from 'react';
import DataTable from 'react-data-table-component';
import "datatables.net";
import "datatables.net-bs4"
import "datatables.net-responsive"


const DataTableComp = (props) =>{

    useEffect(()=>{
      
    },[props.data,props.filterText])
    
    
    return(
        <div>
            <DataTable
                pagination
                paginationComponentOptions={props.paginationComponentOptions&& props.paginationComponentOptions}
                subHeader
                paginationServer={props.paginationServer&& props.paginationServer}
                subHeaderComponent={props.subHeaderComponent&& props.subHeaderComponent}
                noDataComponent="En este momento no hay nada para mostrar"
                columns={props.columns&& props.columns}
                data={props.data&& props.data}
                paginationTotalRows={props.paginationTotalRows && props.paginationTotalRows}
                paginationPerPage={props.paginationPerPage && props.paginationPerPage}
                paginationRowsPerPageOptions={props.paginationRowsPerPageOptions&& props.paginationRowsPerPageOptions}
                // data={props.data}
                className="datosmovil"
                customStyles={props.customStyles && props.customStyles} 
                responsive={props.responsive&& props.responsive}
                onChangePage={props.onChangePage && props.onChangePage}
                conditionalRowStyles={props.conditionalRowStyles}
                selectableRows={props.selectableRows && props.selectableRows}
                onSelectedRowsChange={props.onSelectedRowsChange && props.onSelectedRowsChange}
                clearSelectedRows={props.clearSelectedRows && props.clearSelectedRows}
                sortFunction={props.sortFunction && props.sortFunction}
                noTableHead={props.noTableHead && props.noTableHead}
            />
        </div>
    )
}
export default DataTableComp;